<template>
    <div id="Search">
        <b-form @submit.prevent="handleSubmit">
            <b-row>
                <b-col sm="6" md="3" class="text-left" v-if="!this.$divisionData.typologySelectedAutomatic">
                    <label for="text-password" class="d-block">
                        {{ $trad("search_typologyLabel") }}
                    </label>
                    <div>
                        <b-form-radio v-for="type in typology"
                                      v-model="input.typologySelected"
                                      :key="type.value"
                                      :value="type.value"
                                      inline
                                      :class="{ 'is-invalid': submitted && $v.input.typologySelected.$error }">{{ type.text }}</b-form-radio>
                        <div v-if="submitted && !$v.input.typologySelected.required" class="invalid-feedback">{{ $trad("search_error_typologyField") }}</div>
                    </div>
                </b-col>
                <div v-if="this.$divisionData.filterByLanguage && submitted && (input.typologySelected == undefined || input.typologySelected == null)" style=" width: 100%; margin-top: 0.25rem; font-size: 80%; color: #dc3545;">{{ $trad("search_error_typologyField") }}</div>
               
                <b-col sm="6" v-bind:md="this.$divisionData.typologySelectedAutomatic ? 4 : 3" class="text-left" v-if="this.$divisionData.showCountry">
                    <label for="lang-document" class="d-block">
                        {{ $trad("search_documentCountryLabel") }}
                    </label>
                    <b-select size="md"
                              v-if="countriesArray && countriesArray.length"
                              v-model="input.countrySelected">
                        <option v-for="country of countriesArray"
                                :key="country.id"
                                :value="country.id">
                            <span v-if="country.id != null">{{country.name}} ({{country.isoCode}})</span>
                            <span v-if="country.id == null">{{country.name}}</span>
                        </option>
                    </b-select>
                    <div v-if="submitted && this.$divisionData.showCountry && (input.countrySelected == undefined || input.countrySelected == null)" style=" width: 100%; margin-top: 0.25rem; font-size: 80%; color: #dc3545;">{{ $trad("search_error_countryField") }}</div>
                </b-col>
                
                <b-col sm="6" v-bind:md="this.$divisionData.typologySelectedAutomatic ? 4 : 3" class="text-left" v-if="this.$divisionData.filterByLanguage">
                    <label for="lang-document" class="d-block">
                        {{ $trad("search_documentLangLabel") }}
                    </label>
                    <b-select size="md"
                              v-if="langArray && langArray.length"
                              v-model="input.languageSelected">
                        <option v-for="lang of langArray"
                                :key="lang.id"
                                :value="lang.id">
                            <span  v-if="lang.id != null">{{lang.name}} ({{lang.isoCode}})</span>
                            <span  v-if="lang.id == null">{{lang.name}}</span>
                        </option>
                    </b-select>
                    <div v-if="submitted && this.$divisionData.filterByLanguage && (input.languageSelected == undefined || input.languageSelected == null)" style=" width: 100%; margin-top: 0.25rem; font-size: 80%; color: #dc3545;">{{ $trad("search_error_languageField") }}</div>
                </b-col>

                <b-col sm="6" v-bind:md="this.$divisionData.typologySelectedAutomatic ? 4 : 3" class="text-left">
                    <label for="text-password" class="d-block">
                        {{ $trad("search_codeLabel") }}
                        
                    </label>
                    <!-- :placeholder="$trad('search_codePlaceholderInput')" -->
                    <b-form-input id="input-searchString" size="md"
                                  v-model="input.searchString"
                                  :class="{ 'is-invalid': submitted && $v.input.searchString.$error }"
                                  ></b-form-input>
                    <div v-if="submitted && !$v.input.searchString.required" class="invalid-feedback">{{ $trad("search_error_codeField") }}</div>
                    <small>{{ $trad('search_codePlaceholderInput') }}</small>
                </b-col>
            </b-row>
            <b-row class="mt-5">
                <b-col sm="12" md="12">
                    <b-button class="py-2"
                              variant="info"
                              @click="validateInput()">
                        {{ $trad("search_searchBtn") }}
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
        <Modal title="title2" modalMessage="bodyMessage" @result="typologyDoctorAcceptance" />

    </div>

</template>

<script>
    import { required } from "vuelidate/lib/validators"
    import EventBus from '../eventBus'
    import Modal from './utils/modal'
    import axios from 'axios';

    export default {
        name: 'Search',
        components: {
            Modal
        },
        data() {
            return {
                typology: [
                    {
                        'value': 1,
                        'text': this.$trad("patient"),
                    },
                    {
                        'value': 2,
                        'text': this.$trad("doctor"),
                    },
                ],
                langArray: [],
                countriesArray: [],
                input: {
                    typologySelected: '',
                    divisionSelected: '',
                    searchString: '',
                    pageNumber: 0,
                    languageSelected: null,
                    languageSelectedExplicited: null,
                    countrySelected: null,
                    countrySelectedExplicited: null,
                },
                submitted: false,
                acceptance: 0
            }
        },
        created() {
            this.input.divisionSelected = this.$divisionData.divisionId;

            this.getlanguagesDocument().then((result) => {
                this.langArray = result;
                var placeholder = {}
                placeholder.id = null
                placeholder.name = this.$trad("search_languagePlaceholderInput")
                placeholder.isoCode = "-"
                this.langArray.unshift(placeholder);

                var found = false;
                this.langArray.forEach((v) => {
                    if (v.isoCode.toLowerCase() == this.$currentLang.text.toLowerCase()) {
                        found = true;
                        this.setLocale(v.id, v.isoCode);
                    }
                });
                //console.log("found", found)
                if (!found) {
                    //if (this.langArray.length > 0) {
                    //    var lang = this.langArray[0];
                    //    this.setLocale(lang.id, lang.isoCode, true);
                    //}
                    // default
                    this.setLocale(0, "en", true);
                }
            });
            this.getCountriesDocument();

            if (this.$divisionData.typologySelectedAutomatic) {
                // osartis
                this.input.typologySelected = this.$divisionData.isPatient ? 1 : 2;
            }
            
        },
        methods: {
            validateInput: function () {
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }

                if (this.$divisionData.typologySelectedAutomatic && this.input.languageSelected == null) {
                    return; 
                }

                if (this.$divisionData.showCountry && this.input.countrySelected == null) {
                    return; 
                }

                this.$emit('validateSearch', this.input, true);
                EventBus.$emit('getProductList', this.input)

            },
            getlanguagesDocument: function () {
                var params = 'divisionSelected=' + this.$divisionData.divisionId;
                return axios.get(process.env.VUE_APP_BACKOFFICE_URL + '/api/Languages/Documents?' + params, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        // JSON responses are automatically parsed.
                        return response.data
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            },
            typologyDoctorAcceptance(value) {
                if (value != 1) {
                    this.input.typologySelected = null
                }
                this.acceptance = value
            },
            getCountriesDocument: function () {
                // countries
                var paramsCountry = 'divisionSelected=' + this.$divisionData.divisionId;
                return axios.get(process.env.VUE_APP_BACKOFFICE_URL + '/api/Countries?'
                    + paramsCountry, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        // JSON responses are automatically parsed.
                        this.countriesArray = response.data;                        

                        var placeholder = {}
                        placeholder.id = null
                        placeholder.name = this.$trad("search_countryPlaceholderInput")
                        placeholder.isoCode = "-"
                        this.countriesArray.unshift(placeholder);

                        var found = false;
                        this.countriesArray.forEach((v, i) => {
                            if (v.isoCode.toLowerCase() == this.$currentCountry.code.toLowerCase()) {
                                found = true;
                                this.setCountry(v.id, v.isoCode, v.name);
                            }
                        });
                        if (!found) {
                            //if (this.countriesArray.length > 0) {
                            //    var lang = this.countriesArray[0];
                            //    this.setLocale(lang.id, lang.isoCode, true);
                            //}
                            // default
                            var code = "gbr"
                            axios.get(process.env.VUE_APP_BACKOFFICE_URL + '/api/Countries/Code/' +
                                code, {
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })
                                .then(response => {
                                    // JSON responses are automatically parsed.
                                    var country = response.data;
                                    this.setCountry(country.id, country.code, country.name);
                                    return this.countriesArray;
                                })
                                .catch(error => {
                                    this.errors.push(error);
                                    var placeholder = {}
                                    placeholder.id = null
                                    placeholder.name = this.$trad("search_countryPlaceholderInput")
                                    placeholder.isoCode = "-";
                                    this.countriesArray.unshift(placeholder);                                   
                                });
                        } else {                            
                            return this.countriesArray;
                        }
                    })
                    .catch(error => {
                        this.errors.push(error)
                    });
            },
            setCountry(id, countryIsoCode, countryName) {
                //country = country.toLowerCase();
                this.$currentCountry.value = id;
                this.$currentCountry.code = countryIsoCode;
                this.$currentCountry.text = countryName;

                this.input.countrySelected = id;
                this.input.countrySelectedExplicited = countryIsoCode;
            },
            setLocale(id, locale) {
                this.input.languageSelected = id;
                this.input.languageSelectedExplicited = locale;
            },
            handleSubmit() {
                this.validateInput();
            },
        },
        validations: {
            input: {
                searchString: { required },
                typologySelected: { required }
            }
        },
        watch: {
            'input.typologySelected': function (val) {
                if (val == 2 && this.$divisionData.divisionId != 2) {
                    this.$bvModal.show('modal-1')
                }
            },
        }
    };
</script>
<style scoped>

    .form-group {
        margin: 0 60px;
    }

    input[type=text], select {
        width: 80% !important;
    }

    .form-inline label,
    label {
        text-transform: uppercase;
        font-weight: bold;
        padding-bottom: 15px;
        justify-content: unset;
    }

    .btn.btn-primary {
        margin-top: 30px;
    }
</style>