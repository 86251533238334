import Vue from 'vue';
import App from './App.vue';
import Vuelidate from "vuelidate";
import router from './router'
import axios from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CountryFlag from 'vue-country-flag'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VuePageTransition from 'vue-page-transition'
import './gtm.js' // only production

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Vuelidate)
Vue.use(VuePageTransition)
Vue.use(axios)

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('country-flag', CountryFlag)



//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;

// use beforeEach route guard to set the language
router.beforeEach(async (to, from, next) => {

    // use the language from the routing param or default language
    let language = to.params.lang.toLowerCase();
    if (!language) {
        language = 'en'
    }

    // set the current language for i18n.
   // i18n.locale = language

    // division
    var mainUrl = window.location.href.toString().toLowerCase();
    var mainColor = "#032F50"
    var logo = "#032F50"
    var divisionId = 1
    var showCountry = false
    var filterByLanguage = true
    var isPatient = false; // doctor
    var typologySelectedAutomatic = false
    var footerData = new Object();
    var title = "";
    var favicon = "";
    var homeMinHeight = "53vh";
    if (mainUrl.includes("osartis")) {
        if (mainUrl.includes("patient")) {
            isPatient = true;
            title = "Patient info Osartis";
        } else {
            title = "IFU Osartis";
        }
        mainColor = '#1C3B63';
        logo = 'osartis_bianco.png';
        divisionId = 2;
        footerData.name = "Osartis GmbH"
        footerData.address = "Auf der Beune 101 | D-64839 Münster"
        footerData.phone = "+49 (0) 6071 - 929 - 0"
        footerData.fax = "+49 (0) 6071 - 929 - 100"
        footerData.web = "www.osartis.de"
        footerData.capSoc = ""
        footerData.piva = ""
        footerData.rea = ""
        showCountry = true;
        typologySelectedAutomatic = true;
        filterByLanguage = true;
        favicon = "/favicon_osartis.ico";
        homeMinHeight = "67vh";
    } else if (mainUrl.includes("tecres")) {
        mainColor = '#032F50';
        logo = 'Logo_Tecres_orizzontale.png';
        divisionId = 1;
        footerData.name = "Tecres S.P.A"
        footerData.address = "Via A. Doria, 6 - 37066 Sommacampagna (VR) Italy"
        footerData.phone = "+39 0459217311"
        footerData.fax = "+39 0459217330"
        footerData.web = "www.tecres.com"
        footerData.capSoc = "Cap. Soc.: € 850.000,00 i.v."
        footerData.piva = "P.iva: 02042700233"
        footerData.rea = "REA n°: 211053"
        footerData.privacy = "https://www.tecres.it/privacy-policy"
        footerData.cookie = "https://www.tecres.it/cookies-policy"
        showCountry = true;
        typologySelectedAutomatic = false;
        filterByLanguage = true;
        title = "IFU Tecres";
        favicon = "/favicon.ico";
        homeMinHeight = "53vh";
    } else {
        // default
        mainColor = '#032F50';
        logo = 'Logo_Tecres_orizzontale.png';
        divisionId = 1;
        footerData.name = "Tecres S.P.A"
        footerData.address = "Via A. Doria, 6 - 37066 Sommacampagna (VR) Italy"
        footerData.phone = "+39 0459217311"
        footerData.fax = "+39 0459217330"
        footerData.web = "www.tecres.com"
        footerData.capSoc = "Cap. Soc.: € 850.000,00 i.v."
        footerData.piva = "P.iva: 02042700233"
        footerData.rea = "REA n°: 211053"
        footerData.privacy = "https://www.tecres.it/privacy-policy"
        footerData.cookie = "https://www.tecres.it/cookies-policy"
        showCountry = true;
        typologySelectedAutomatic = false;
        filterByLanguage = true;
        title = "IFU Tecres";
        favicon = "/favicon.ico";
        homeMinHeight = "53vh";
    }

    document.title = title;   

    Vue.prototype.$divisionData = {
        mainColor: mainColor,
        logo: logo,
        footer: footerData,
        divisionId: divisionId,
        typologySelectedAutomatic: typologySelectedAutomatic,
        isPatient: isPatient,
        showCountry: showCountry,
        filterByLanguage: filterByLanguage,
        favicon: favicon,
        homeMinHeight: homeMinHeight
    };

    //Vue.prototype.$currentLang = 
    // traductions
    var languageId = Vue.prototype.$currentLang.value;
    var refresh = languageId != '0';
    Vue.prototype.$currentLang.text = language;

    await Vue.prototype.$changeLanguage(language, refresh);
    await Vue.prototype.$getDivisionDetail(refresh);

    next()

})


Vue.prototype.$DoctorAcceptance = 0;

// division
Vue.prototype.$divisionData = {
    'mainColor': '#032F50',
    'buttonColor': '#17a2b8',
    'logo': 'Logo_white.png'
};
// get division detail
Vue.prototype.$getDivisionDetail = async function (refresh) {
    // console.log("process.env", process.env);
    await axios.get(process.env.VUE_APP_BACKOFFICE_URL + '/api/Divisions/' + this.$divisionData.divisionId, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            var data = response.data;
            //console.log(data);
            Vue.prototype.$divisionData.showCountry = data.countrySectionEnabled;
            Vue.prototype.$divisionData.filterByLanguage = data.countrySectionEnabled;
            //if (refresh) {
            //    window.location.assign(window.location.origin + process.env.VUE_APP_FRONTOFFICE_URL + "/" + languageIsoCode)
            //}
        })
        .catch(error => {
            console.log("error", error)
        });
}

// traductions
Vue.prototype.$changeLanguage = async function (languageIsoCode, refresh) {
    var domainUrl = window.location.origin;
    // var debug = window.location.origin.includes("localhost");
    // if (debug) {
    //     domainUrl = "https://patientinfo.osartis.de";
    // }
    var params = 'divisionSelected=' + this.$divisionData.divisionId;
    params = params +'&domainUrl=' + domainUrl;
    await axios.get(process.env.VUE_APP_BACKOFFICE_URL + '/api/Traductions/Code/' + languageIsoCode + '?' + params, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            var data = response.data.traductionList;
            Vue.prototype.$tradData = data;

            //console.log(window.location);
            if (refresh) {
                window.location.assign(window.location.origin + process.env.VUE_APP_FRONTOFFICE_URL + "/" + languageIsoCode)
            }
        })
        .catch(error => {
            console.log("error", error)
        });
}

Vue.prototype.$tradData = [
    {
        "key": "Test",
        "value": "Test2"
    }
];
Vue.prototype.$trad = function (key) {

    if (key == null) {
        return defaultValue;
    }
    key = key.replace("$", "");
    key = key.toLowerCase();

    var defaultValue = `[${key}]`;
    var dataList = Vue.prototype.$tradData;
    for (var i = 0; i < dataList.length; i++) {
        var data = dataList[i];
        if (data.key.toLowerCase() == key) {
            return data.value == null ? defaultValue : data.value;
        }
    }
    return defaultValue;
}

new Vue({
    router,
    //i18n,
    render: h => h(App)
}).$mount('#app');