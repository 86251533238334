<template>
  <transition name="modal-fade">
    <b-modal id="modal-1" ref="modal-1" no-close-on-backdrop hide-footer>
      <template #modal-header="{  }" >
      <h5>{{ $trad("DisclaimerDoctor_Title") }}</h5>
    </template>
        <p class="my-4">{{ $trad("DisclaimerDoctor_Message") }}</p>
           <b-button size="sm" variant="outline-secondary" @click="cancel()" class="mr-2">
            {{ $trad("DisclaimerDoctor_Cancel") }}
          </b-button>
         
           <b-button size="sm" variant="success" @click="accept()" class="ml-2">
            {{ $trad("DisclaimerDoctor_Accept") }}
          </b-button>
  </b-modal>
  </transition>
</template>

<script>
    export default {
      name: 'Modal',
      props: [
        'title',
        'modalMessage'
      ],
      methods: {
        accept: function(){
          this.$emit('result', 1);
          this.$refs['modal-1'].hide();
        },
        cancel: function(){
          this.$emit('result', 0);
          this.$refs['modal-1'].hide();
        }
      }
    };
</script>

<style scoped>

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>