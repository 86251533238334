<template>
    <div id="Presentation">
        <b-row align-v="center" align-h="center" class="item">
                <b-col>
                    <h2 v-html="$trad('presentation_ifu_title')"></h2>
                    <p v-html="$trad('presentation_ifu_descr')"></p>
                </b-col>
        </b-row>
    </div>

</template>

<script>

    export default {
        name: 'Presentation',
    };
</script>