<template>
    <div id="ProductList">
        <b-container>
            <b-row>
                <b-col v-if="isLoading">
                <div class="text-center">
                    <b-spinner variant="primary" label="Text Centered"></b-spinner>
                </div>
            </b-col>
            </b-row>

        <b-row>
            
            <b-col>
                <div v-if="results.list && results.list.length > 0">
                    <h5 class="mb-5">
                        {{ $trad("product_titleList") }}
                    </h5>

                    <b-list-group-item 
                        v-for="(result) of results.list"
                        :key="result.product.id"
                        class="flex-column align-items-start py-3">

                        <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1" v-if="result.product.description">{{ result.product.description }}</h5>
                            <h5 class="mb-1" v-else>{{ result.product.name }}</h5>
                            <b-button variant="info" v-on:click="onClickDetail(result.product.id)">
                                {{ $trad("product_viewDocumentAttached") }}
                            </b-button>
                        </div>
                    </b-list-group-item>

                </div>
                <div v-else>
                        <h3>{{ $trad("generic_errorSearchMsg") }}</h3>
                </div>
            </b-col>
        </b-row>
    </b-container>
    </div>
</template>

<script>
    import axios from "axios";
    import EventBus from '../eventBus'

    export default {
        name: 'ProductList',
        props: {
            searchInput: {
                required: true
            },
        },
        data() {
            return {
                isLoading: false,
                results: [],
                errors: [],
                params: [],
            }
        },
        methods: {
            onClickDetail: function (id) {
                this.$emit('detailClick', id, true);
                EventBus.$emit('getProductDetail', id
                    + '?' + this.params)
            },
            getProductList: function (searchInput) {
                this.params = 'typologySelected=' + searchInput.typologySelected
                    + '&divisionSelected=' + searchInput.divisionSelected;

                if (this.$divisionData.filterByLanguage) {
                    this.params += '&languageSelected=' + searchInput.languageSelected;
                }
                if (this.$divisionData.showCountry) {
                    //this.params += '&countrySelected=' + this.$currentCountry.value;
                    this.params += '&countrySelected=' + searchInput.countrySelected;
                }
                

               // console.log(process.env.VUE_APP_BACKOFFICE_URL + '/api/Products?' + this.params)
                // this.$router.push({ query: { typologySelected: searchInput.typologySelected }, silent:true});
                history.pushState(
                    {},
                    null,
                    this.$route.path + '?' 
                    + 'typologySelected='+ searchInput.typologySelected
                    + '&languageSelected='+ searchInput.languageSelectedExplicited
                    + '&countrySelected='+ searchInput.countrySelectedExplicited
                    + '&searchString='+ searchInput.searchString
                )

                axios.get( process.env.VUE_APP_BACKOFFICE_URL + '/api/Products?'
                    + this.params
                    +'&searchString='+ encodeURIComponent(searchInput.searchString)
                    +'&pageNumber='+ searchInput.pageNumber, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        // JSON responses are automatically parsed.
                        this.results = response.data;
                        // console.log(this.results)
                        this.isLoading = false;
                    })
                    .catch(e => {
                        this.errors.push(e)
                    });
            }
        },
        mounted () {
            EventBus.$on('getProductList', (searchInput) => {
                this.isLoading = true;
                this.getProductList(searchInput);
            })
        }
    };
</script>
<style>
.list-group-item {
    background-color: transparent!important;
    border: 0!important;
    border-bottom: 1px solid #032f504A!important;
}

.show-enter-active,
.show-leave-enter {
    transform: translateX(0);
    transition: all .3s linear;
}
.show-enter,
.show-leave-to {
    transform: translateX(100%);
}
</style>