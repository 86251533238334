<template>
    <div id="Home">
        <Menu />

        <b-container fluid class="p-5 text-center" id="HomeContainer" :style="{ 'min-height': this.$divisionData.homeMinHeight }">
            <b-row class="mx-auto my-5" v-if="true">
                <b-col class="componentBasicStyle componentSearch p-5">
                    <Presentation />
                </b-col>
            </b-row>

            <b-row class="mx-auto my-5">
                <b-col class="componentBasicStyle componentSearch p-5">
                    <Search @validateSearch="onSubmitSearch" />
                </b-col>
            </b-row>

            <b-row class="mx-auto my-5" v-show="listActive">
                <transition name="slide-fade">
                    <b-col class="componentBasicStyleFull componentList mx-4 py-5 px-4">
                        <ProductList :searchInput="searchInput" @detailClick="onSubmitDetail" />
                    </b-col>
                </transition>

                <!--info software-->
                <!--<div>
                <b-row align-v="center" align-h="center" class="item">
                    <label>
                        {{ $trad("software_view_eifu_info") }}
                    </label>
                </b-row>
            </div>-->

                <transition name="slide-fade">
                    <b-col class="componentBasicStyle componentDetail mx-4 py-5 px-4" v-show="detailActive">
                        <ProductDetail :detailInput="detailInput" />
                    </b-col>
                </transition>
            </b-row>
        </b-container>

        <Footer />

    </div>
</template>

<script>
    import Presentation from '../components/Presentation.vue';
    import Search from '../components/Search.vue';
    import ProductList from '../components/ProductList.vue';
    import ProductDetail from '../components/ProductDetail.vue';
    import Footer from '../components/navigation/Footer.vue';
    import Menu from '../components/navigation/Menu.vue';

    export default {
        name: 'Home',
        components: {
            Presentation,
            Search,
            ProductList,
            ProductDetail,
            Footer,
            Menu
        },
        data() {
            return {
                searchInput: Array,
                detailInput: Number,
                searchActive: true,
                listActive: false,
                detailActive: false
            }
        },
        methods: {
            onSubmitSearch(value) {
                this.searchInput = value
                this.listActive = true
                this.detailActive = false
            },
            onSubmitDetail(value) {
                this.detailInput = value
                this.detailActive = true
            }
        }
    };
</script>
<style scoped>

    /* #HomeContainer {
        min-height: 53vh;
    } */

    #Home .container-fluid {
        max-width: 1440px;
    }

    .componentBasicStyleFull {
        margin: 0 auto;
        border-radius: 50px;
        background: linear-gradient(135.37deg, rgba(105, 105, 105, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #EBECF0;
        background-blend-mode: soft-light, normal;
        border: 1px solid rgba(255, 255, 255, 0.4);
        box-sizing: border-box;
        /* box-shadow: -5px -5px 10px #FAFBFF, 5px 5px 10px #A6ABBD; */
        /* border: 1px solid rgba(166, 171, 189, 1); */
        border-radius: 40px;
    }

    .componentBasicStyle {
        margin: 0 auto;
        border-radius: 50px;
        /* background: linear-gradient(135.37deg, rgba(105, 105, 105, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #EBECF0; */
        background-blend-mode: soft-light, normal;
        border: 1px solid rgba(255, 255, 255, 0.4);
        box-sizing: border-box;
        /* box-shadow: -5px -5px 10px #FAFBFF, 5px 5px 10px #A6ABBD; */
        /* border: 1px solid rgba(166, 171, 189, 1); */
        border-radius: 40px;
    }

    .componentDetail {
        background: linear-gradient(135.37deg, rgba(3,47,80, 0.4) 4.29%, rgba(3,47,80, 0.4) 75.6%), #032f50 !important;
        color: #fff;
    }
</style>