import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home.vue'

Vue.use(Router)

Vue.prototype.$currentLang = Vue.prototype.$currentLang ? Vue.prototype.$currentLang : { 'value': '0', 'text': navigator.language.split("-")[0] };
Vue.prototype.$currentCountry = Vue.prototype.$currentCountry ? Vue.prototype.$currentCountry : { 'value': '0', 'code': navigator.language.split("-")[1] };

export default new Router({
    mode: 'history',
    base: process.env.VUE_APP_FRONTOFFICE_BASE_URL,
    routes: [
        // {
        //     path: '/',
        //     redirect: `/${i18n.locale}`
        // },
        {
            path: '/:lang',
            component: {
                render (c) { return c('router-view') }
            },
            children: [
                {
                    path: '/',
                    name: 'Home',
                    component: Home,
                    meta: { transition: 'zoom' },
                },
                // {
                //     path: 'product-detail/:id',
                //     name: 'Product Detail',
                //     component: ProductDetail,
                //     meta: { transition: 'zoom' },
                // },
            ]
        },
        { path: '*', redirect: `/${Vue.prototype.$currentLang.text}`}
    ]
})