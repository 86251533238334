<template>
    <div id="Menu" v-bind:style="{ 'background-color': this.$divisionData.mainColor }">
        <b-navbar type="dark">
            <b-container id="nav-collapse" is-nav>

                <router-link :to="`/${this.$currentLang.text}`" class="navbar-brand">
                    <!--<img src="../../assets/Logo_white.png" />-->
                    <img :src="image" v-bind:style="{ 'max-width': imageWidth}" />
                </router-link>
                <!--                <b-link :href="logo.tecres" >-->
                <!--                </b-link>-->
                <!-- Right aligned nav items -->
                
                <!--countries-->
                <!--<b-navbar-nav class="ml-auto d-flex justify-content-center align-items-center" v-if="this.$divisionData.showCountry">
                    <font-awesome-icon icon="globe" color="#ffffff" />

                    <b-nav-item-dropdown v-if="countriesArray && countriesArray.length"
                                         :text="currentCountry.text"
                                         v-model="currentCountry.value"
                                         right
                                         id="listCountry">
                        <b-dropdown-item v-for="country of countriesArray"
                                         @click="setCountry(country.id, country.isoCode, country.name)"
                                         :key="country.id">
                            <country-flag :country='country.isoCode.toString().toLowerCase()' size='small' /> {{ country.name }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>-->

                <!--languages-->
                <b-navbar-nav class="ml-5 d-flex justify-content-center align-items-center">
                    <font-awesome-icon icon="globe" color="#ffffff" />
                    <b-nav-item-dropdown v-if="langArray && langArray.length"
                                         :text="this.$currentLang.text"
                                         v-model="this.$currentLang.text"
                                         right
                                         id="listLang">
                        <b-dropdown-item v-for="lang of langArray"
                                         @click="setLocale(lang.id, lang.isoCode, false)"
                                         :key="lang.id">
                            {{ lang.name }} ({{ lang.isoCode.toString() }})
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>

            </b-container>
        </b-navbar>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'Menu',
        data() {
            return {
                image: require(`@/assets/Logo/${this.$divisionData.logo}`),
                langArray: [],
                countriesArray: [],
                currentCountry: {},
                errors: [],
                imageWidth: this.$divisionData.divisionId == 2 ? '100px' : '130px',
                logo: {
                    tecres: process.env.VUE_APP_FRONTOFFICE_URL
                }
            }
        },
        methods: {
            //aggiorno la lingua selezionata
            setLocale(id, locale, refresh) {
                locale = locale.toLowerCase();
                this.$currentLang.value = id;
                this.$currentLang.text = locale;

                if (refresh) {
                    this.$changeLanguage(locale, true);
                } else {
                    if (!this.$router.history.current.path.includes(locale)) {
                        this.$router.push({
                            params: { lang: locale.toLowerCase() }
                        });
                    }

                }
            },
            setCountry(id, countryIsoCode, countryName) {
                //country = country.toLowerCase();
                this.$currentCountry.value = id;
                this.$currentCountry.code = countryIsoCode;
                this.$currentCountry.text = countryName;
                this.currentCountry = this.$currentCountry;
            }
        },
        // Richiamo tutte le lingue disponibili
        created() {
            // languages frontend
            var params = 'divisionSelected=' + this.$divisionData.divisionId;
            axios.get(process.env.VUE_APP_BACKOFFICE_URL + '/api/Languages?'
                + params, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    // JSON responses are automatically parsed.
                    this.langArray = response.data;
                    var found = false;
                    this.langArray.forEach((v) => {
                        if (v.isoCode.toLowerCase() == this.$currentLang.text.toLowerCase()) {
                            found = true;
                        }
                    });
                    //console.log("found", found)
                    if (!found) {
                        //if (this.langArray.length > 0) {
                        //    var lang = this.langArray[0];
                        //    this.setLocale(lang.id, lang.isoCode, true);
                        //}
                        // default
                        this.setLocale(0, "en", true);
                    }
                })
                .catch(error => {
                    this.errors.push(error)
                });

            // countries
            //this.currentCountry = this.$currentCountry;
            //var paramsCountry = 'divisionSelected=' + this.$divisionData.divisionId;
            //axios.get(process.env.VUE_APP_BACKOFFICE_URL + '/api/Countries?'
            //    + paramsCountry, {
            //    headers: {
            //        'Content-Type': 'application/json',
            //    },
            //})
            //    .then(response => {
            //        // JSON responses are automatically parsed.
            //        this.countriesArray = response.data;
            //        var found = false;
            //        this.countriesArray.forEach((v) => {
            //            if (v.isoCode.toLowerCase() == this.$currentCountry.code.toLowerCase()) {
            //                found = true;                            
            //                this.setCountry(v.id, v.isoCode, v.name);
            //            }
            //        });
            //        if (!found) {
            //            //if (this.countriesArray.length > 0) {
            //            //    var lang = this.countriesArray[0];
            //            //    this.setLocale(lang.id, lang.isoCode, true);
            //            //}
            //            // default
            //            var code = "gbr"
            //            axios.get(process.env.VUE_APP_BACKOFFICE_URL + '/api/Countries/Code/' +
            //                code, {
            //                headers: {
            //                    'Content-Type': 'application/json',
            //                },
            //            })
            //                .then(response => {
            //                    // JSON responses are automatically parsed.
            //                    var country = response.data;
            //                    this.setCountry(country.id, country.code, country.name);
            //                })
            //                .catch(error => {
            //                    this.errors.push(error)
            //                });

            //        }
            //    })
            //    .catch(error => {
            //        this.errors.push(error)
            //    });

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .navbar {
        /*background: #032F50;*/
        &-brand {
            img {
                max-width: 60px;
                height: auto;
            }
        }
    }

    ul.dropdown-menu.dropdown-menu-right.show {
        column-count: 3 !important;
    }
</style>