<template>
    <div id="app">
        <!-- Google Tag Manager (noscript) -->
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5HMX9F8L" height="0" width="0"
                style="display:none;visibility:hidden"></iframe></noscript>
        <!-- End Google Tag Manager (noscript) -->

        <!--<Menu />-->
        <div id="app_main">
            <vue-page-transition>
                <router-view />
            </vue-page-transition>
        </div>
        <!--<Footer />-->
    </div>
</template>

<script>
import Menu from './components/navigation/Menu.vue';
import Footer from './components/navigation/Footer.vue';

export default {
    name: 'app',
    components: {
        Menu,
        Footer
    },
    created() {
        // console.log('process.env.BASE_URL', process.env.BASE_URL);
        // console.log('process.env.VUE_APP_FRONTOFFICE_URL', process.env.VUE_APP_FRONTOFFICE_URL);
        // console.log('process.env.VUE_APP_FRONTOFFICE_BASE_URL', process.env.VUE_APP_FRONTOFFICE_BASE_URL);
        // console.log('divisionData', this.$divisionData);

        const favicon = document.getElementById("favicon");
        if (favicon && this.$divisionData.favicon) {
            favicon.href = this.$divisionData.favicon;
        }
    },
    mounted() {

    }
};
</script>

<style lang="scss">
@import './assets/styles/custom.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
</style>

<style>
@font-face {
    font-family: "Muli";
    src: local("Muli"),
        url(./fonts/Muli/Muli.ttf) format("truetype");
}

html,
body {
    font-family: "Muli", Helvetica, Arial;
}
</style>

<style scoped>
html,
body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    font-family: "Muli", Helvetica, Arial;
}

#app_main {
    /*display: flex;*/
    min-height: 600px;
    height: 100%;
    /*text-align: center;*/
    flex-flow: row wrap;
    overflow-x: hidden;
    background-color: #F2F2F2;
}

#app_main #HomeContainer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100vw;
}
</style>